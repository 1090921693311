<!-- Start Breadcrumb 
    ============================================= -->
<div class="breadcrumb-area gradient-bg text-light text-center">
  <!-- Fixed BG -->
  <div class="fixed-bg" style="background-image: url(assets/img/shape/1.png)"></div>
  <!-- Fixed BG -->
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-8 offset-lg-2">
        <h1>About CLIFF.</h1>
        <ul class="breadcrumb">
          <li>
            <a routerLink="/"><i class="fas fa-home"></i> Home</a>
          </li>

          <li class="active">About</li>
        </ul>
      </div>
    </div>
  </div>
</div>
<!-- End Breadcrumb -->

<!-- Start Our About
  ============================================= -->
<div class="about-area bg-gray overflow-hidden rectangular-shape default-padding">
  <div class="container-fluid">
    <div class="about-items choseus-items right-thumb">
      <div class="row align-center">
        <div class="col-lg-6">
          <div class="info wow fadeInLeft">
            <p>WHO WE ARE</p>
            <h2>{{ aboutusSpecificData?.aboutus_title1 }}</h2>
            <p [innerHTML]="aboutusSpecificData?.about_description1"></p>
            <!-- <h4>3 Easy Steps to Get Started</h4> -->
            <ul *ngIf="aboutusSpecificData?.about_points1.length !== 0">
              <li *ngFor="let keypoints of aboutusSpecificData?.about_points1">
                <span><i class="ti-check"></i> {{ keypoints }}</span>
              </li>
            </ul>
            <a routerLink="/contact-us" class="btn circle btn-md btn-gradient wow fadeInUp mt-3">Contact us</a>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="thumb wow fadeInUp" data-wow-delay="0.5s">
            <img src="assets/img/illustration/9.png" alt="Thumb" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Our About -->

<!-- Star Features
  ============================================= -->
<div class="features-list bottom-less text-light bg-gray default-padding">
  <div class="container-fluid">
    <div class="row">
      <!-- Single Item -->
      <div class="single-item col-lg-4">
        <div class="item wow fadeInUp" data-wow-delay="400ms">
          <h4>Our history</h4>
          <p [innerHTML]="aboutusSpecificData?.history_description"></p>
        </div>
      </div>
      <!-- Single Item -->
      <!-- Single Item -->
      <div class="single-item col-lg-4">
        <div class="item wow fadeInUp" data-wow-delay="500ms">
          <h4>Our mission</h4>
          <p [innerHTML]="aboutusSpecificData?.mission_description"></p>
        </div>
      </div>
      <!-- Single Item -->
      <!-- Single Item -->
      <div class="single-item col-lg-4">
        <div class="item wow fadeInUp" data-wow-delay="600ms">
          <h4>Our vision</h4>
          <p [innerHTML]="aboutusSpecificData?.vision_description"></p>
        </div>
      </div>
      <!-- Single Item -->
    </div>
  </div>
  <!-- Fixed BG -->
  <div class="fixed-bg" style="background-image: url(assets/img/shape/9.svg)"></div>
  <!-- Fixed BG -->
</div>
<!-- End Features -->

<!-- Star Our Story
  ============================================= -->
<!-- <div class="our-story-area default-padding-top">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 offset-lg-2">
        <div class="site-heading text-center">
          <h4>Our Story</h4>
          <h2>How to get started</h2>
        </div>
      </div>
    </div>
    <div class="story-items text-center">
      <div class="row">
        
        <div class="col-lg-3 col-md-6 single-item">
          <div class="item">
            <span>01</span>
            <h4>Company started</h4>
            <p>15/02/2015</p>
          </div>
        </div>
        
        <div class="col-lg-3 col-md-6 single-item">
          <div class="item">
            <span>02</span>
            <h4>Reached 2k+ Customers</h4>
            <p>09/08/2016</p>
          </div>
        </div>
        
        <div class="col-lg-3 col-md-6 single-item">
          <div class="item">
            <span>03</span>
            <h4>Increase Workers</h4>
            <p>26/08/2018</p>
          </div>
        </div>
        
        <div class="col-lg-3 col-md-6 single-item">
          <div class="item">
            <span>04</span>
            <h4>Win Multiple Award</h4>
            <p>09/02/2020</p>
          </div>
        </div>
        
      </div>
    </div>
  </div>
</div> -->
<!-- End Our Story -->

<!-- Start Skill Area
  ============================================= -->
<div class="start-us-area overflow-hidden bg-gradient text-light default-padding">
  <!-- Fixed BG -->
  <div class="fixed-bg" style="background-image: url(assets/img/shape/1.png)"></div>
  <!-- Fixed BG -->
  <div class="container-fluid">
    <div class="row align-center">
      <div class="col-lg-6">
        <div class="thumb wow fadeInUp" data-wow-delay="400ms">
          <img src="assets/img/illustration/7.png" alt="Thumb" class="img-fluid" />
        </div>
      </div>
      <div class="col-lg-6">
        <div class="info wow fadeInLeft">
          <p>WHY CHOOSE US</p>
          <h2>{{ aboutusSpecificData?.aboutus_title2 }}</h2>
          <p [innerHTML]="aboutusSpecificData?.about_description2"></p>
          <ul *ngIf="aboutusSpecificData?.about_points2.length !== 0">
            <li *ngFor="let keypoints of aboutusSpecificData?.about_points2">
              <span class="font-bold"><i class="ti-check me-3"></i> {{ keypoints }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <div
  class="skill-area overflow-hidden center-mobile circular-shape default-padding"
>
  <div class="container">
    <div class="skill-items">
      <div class="row">
        <div class="col-lg-6 info">
          <h2>{{ aboutusSpecificData?.aboutus_title2 }}</h2>
          <p [innerHTML]="aboutusSpecificData?.about_description2"></p>
          <ul
            class="style-none list-item color-rev"
            *ngFor="let keypoints of aboutusSpecificData?.about_points2"
          >
            <li>
              <span class="me-2 font-bold"><i class="ti-check"></i></span>
              {{ keypoints }}
            </li>
          </ul>
        </div>
        <div class="col-lg-6 thumb">
          <div class="image-box">
            <img
              class="wow fadeInRight"
              data-wow-delay="0.6s"
              src="assets/img/app/3.jpg"
              alt="Thumb"
            />
            <img
              class="wow fadeInLeft"
              data-wow-delay="0.8s"
              src="assets/img/app/1.jpg"
              alt="Thumb"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->
<!-- End Skill Area -->