<ng-container *ngIf="home_loader == false; else apiData">
  <app-loader></app-loader></ng-container>
<ng-template #apiData>
  <!-- Start Banner ============================================= -->
  <!-- style="
  background-image: url('assets/img/illustration/bg1.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
  " -->
  <div class="banner-area text-combo top-pad-90 rectangular-shape bg-light-gradient default-padding">
    <div class="item default-padding">
      <!-- <figure class="banner-sideshape mb-0">
        <img src="assets/img/illustration/11.png" alt="" class="img-fluid" />
      </figure> -->
      <div class="box-table">
        <div class="box-cell">
          <div class="container-fluid">
            <div class="space">
              <div class="particle"></div>
              <div class="particle"></div>
              <div class="particle"></div>
              <div class="particle"></div>
            </div>
            <!-- <div id="particles-js"></div> -->
            <div class="row">
              <div class="double-items">
                <div class="col-lg-8 info">
                  <h1 class="wow fadeInDown banner-heading" data-wow-duration="1s" style="opacity: 1">
                    {{ homeData?.home_banner_title }}
                  </h1>
                  <h5 class="wow fadeInLeft" data-wow-duration="1.5s"
                    [innerHTML]="homeData?.home_banner_sub_description"></h5>
                  <a class="btn circle btn-md btn-gradient wow fadeInUp" data-wow-duration="1.8s"
                    routerLink="{{ homeData?.home_banner_link }}">Request A Demo <i class="fas fa-plus"></i></a>
                </div>
                <div class="col-lg-4 thumb wow fadeInRight" data-wow-duration="1s">
                  <img [src]="homeData?.home_banner_image" [alt]="homeData?.home_banner_title" class="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <figure class="banner-sideshape2 mb-0">
        <img src="assets/img/illustration/13.png" alt="" class="img-fluid" />
      </figure>
    </div>
  </div>
  <!-- End Banner -->

  <!-- Start Our Features ============================================= -->
  <!-- <div class="services-area left-border default-padding bottom-less">
    <div class="shape-fixed animation-rotation">
      <img src="assets/img/round-shappe.png" alt="Thumb" />
    </div>

    <div class="container-fluid">
      <div class="heading-left">
        <div class="row">
          <div class="col-lg-5">
            <h2> {{ homeData?.home_service_title }} </h2>
          </div>
          <div class="col-lg-6 offset-lg-1">
            <p [innerHTML]="homeData?.home_service_sub_description"></p>
          </div>
        </div>
      </div>

      <div class="services-items">
        <owl-carousel-o [options]="customOptions">
          <ng-container *ngFor="let service of serviceData; let i = index">
            <ng-template carouselSlide [id]="service.service_id">
              <div class="item cursor-pointer">
                <img src="assets/img/icon/1.png" alt="Thumb" />
                <h4>
                  <a (click)="navigateService(service.service_id)">
                    {{service.service_title}}
                  </a>
                </h4>
                <p class="text-four-line-restrict" [innerHTML]="service.service_sub_description"></p>
              </div>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>
    </div>
  </div> -->

  <div class="about-area bg-gray text-center carousel-shadow wavesshape-bottom default-padding-top">
    <div class="container-fluid">
      <div class="about-items">
        <div class="row">
          <div class="col-lg-8 offset-lg-2">
            <div class="heading">
              <h2> {{ homeData?.home_service_title }} </h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-10 offset-lg-1">
            <div class="content">
              <p [innerHTML]="homeData?.home_service_sub_description"></p>
            </div>
          </div>
          <div class="feature-box text-left col-lg-12">
            <div class="row">
              <div class="col-lg-12">
                <owl-carousel-o [options]="customOptions">
                  <ng-container *ngFor="let service of serviceData; let i = index">
                    <ng-template carouselSlide [id]="service.service_id">
                      <div class="item">
                        <div class="info">
                          <h4> {{ service.service_title }} </h4>
                          <p class="text-three-line-restrict" [innerHTML]="service.service_sub_description"></p>
                        </div>
                        <div class="bottom">
                          <!-- <i class="flaticon-operative-system"></i> -->
                          <button class="btn circle btn-sm btn-gradient cursor-pointer"
                            (click)="navigateService(service.service_id)">
                            Read more <i class="fas fa-plus"></i>
                          </button>
                        </div>
                      </div>
                    </ng-template>
                  </ng-container>
                </owl-carousel-o>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="waveshape">
      <img src="assets/img/shape/2.svg" alt="Shape" />
    </div>
  </div>

  <!-- Strip Area Start -->
  <div class="fun-factor-area bg-gray default-padding">
    <!-- Fixed BG -->
    <div class="fixed-bg" style="background-image: url(assets/img/map.svg)"></div>
    <!-- Fixed BG -->
    <div class="container-fluid">
      <div class="client-items text-center">
        <div class="row">
          <div class="col-lg-4 col-md-6 item">
            <div class="fun-fact">
              <h1>
                <b>{{ stripArrayData[0]?.strip_title1 }}</b>
              </h1>
              <span class="medium text-four-line-restrict"
                [innerHTML]="stripArrayData[0]?.strip_sub_description1"></span>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 item">
            <div class="fun-fact">
              <h1>
                <b>{{ stripArrayData[0]?.strip_title2 }}</b>
              </h1>
              <span class="medium text-four-line-restrict"
                [innerHTML]="stripArrayData[0]?.strip_sub_description2"></span>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 item">
            <div class="fun-fact">
              <h1>
                <b>{{ stripArrayData[0]?.strip_title3 }}</b>
              </h1>
              <span class="medium text-four-line-restrict"
                [innerHTML]="stripArrayData[0]?.strip_sub_description3"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Strip Area End -->

  <!-- Sections Area Start -->
  <div *ngFor="let sections of sectionsAllArray; let even = even; let i = index">
    <!-- Section item Start -->
    <div class="about-area overflow-hidden rectangular-shape default-padding">
      <div class="container-fluid">
        <div class="about-items choseus-items right-thumb">
          <div class="row align-center" [ngClass]="even ? '' : 'mobile-column-reverse'">
            <div class="col-lg-8" *ngIf="even">
              <div class="info wow fadeInLeft">
                <h2>{{ sections.section_title }}</h2>
                <h5 *ngIf="sections.section_sub_description !== ''" [innerHTML]="sections.section_sub_description"></h5>
                <ul *ngIf="sections.section_bullets !== ''">
                  <li *ngFor="let bullets of sections.section_bullets">
                    <span><i class="ti-check"></i> {{ bullets }}</span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="thumb wow fadeInUp" data-wow-delay="0.5s">
                <img [src]="sections.section_image" [alt]="sections.section_title" class="br-12" />
              </div>
            </div>
            <div class="col-lg-8" *ngIf="!even">
              <div class="info wow fadeInLeft">
                <h2>{{ sections.section_title }}</h2>
                <h5 *ngIf="sections.section_sub_description !== ''" [innerHTML]="sections.section_sub_description"></h5>
                <ul *ngIf="sections.section_bullets !== ''">
                  <li *ngFor="let bullets of sections.section_bullets">
                    <span><i class="ti-check"></i> {{ bullets }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Section item end -->
  </div>
  <!-- ==== Sections End -->

  <!-- Companies Area Start ============================================= -->
  <div class="bg-gray">
    <div class="container-fluid py-5">
      <div class="row">
        <div class="col-lg-12 text-center">
          <div class="site-heading text-center">
            <h2>Exceptional influence on our clients success.</h2>
          </div>
        </div>
      </div>
      <div>
        <div class="row">
          <div class="col-lg-12">
            <div class="desktop-display-b">
              <img src="assets/img/clients/logo-list.png" alt="" class="img-fluid" />
            </div>
            <div class="mob-display-b">
              <img src="assets/img/clients/logo-mobile-list.png" alt="" class="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Companies Area End -->

  <!-- Contact Us Area Start -->
  <div>
    <div class="px-0">
      <div class="pt-3">
        <div class="row py-5 text-center">
          <div class="col-lg-8">
            <div class="site-heading">
              <h2> Elevate your digital experience through transformative journeys. </h2>
            </div>
          </div>
          <div class="col-lg-4">
            <a class="btn circle btn-md btn-gradient wow fadeInUp" data-wow-duration="1.8s" routerLink="/contact-us">
              GET IN TOUCH <i class="fas fa-plus"></i></a>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 px-0 text-center">
            <img src="assets/img/clients/design.png" alt="Thumb" class="img-fluid" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Contact Us Area End -->
</ng-template>