<footer>
  <div class="container-fluid">
    <div class="f-items default-padding">
      <div class="row">
        <div class="equal-height col-lg-4 col-md-6 item">
          <!-- Logo -->
          <div class="f-item about">
            <img [src]="footerSpecificData?.footer_logo" alt="Logo" width="200" />
            <p [innerHTML]="footerSpecificData?.footer_description"></p>
          </div>
          <!-- Social links -->
          <div class="f-item contact">
            <ul class="social">
              <li class="facebook" *ngFor="let social of footerSpecificData?.footer_social_links">
                <a href="{{ social.title }}" *ngIf="social.image_url !== ''" target="_blank">
                  <img [src]="social.image_url" [alt]="social.title" class="br-5" /></a>
              </li>
            </ul>
          </div>
        </div>
        <!-- Quick Links  -->
        <div class="equal-height col-lg-2 col-md-6 item">
          <div class="f-item link">
            <h4 class="widget-title">Quick Links</h4>
            <ul>
              <li *ngFor="let links of footerSpecificData?.footer_quick_links">
                <a routerLink="{{ links.quick_link_url }}">{{ links.title }}</a>
              </li>
            </ul>
          </div>
        </div>
        <!-- Services -->
        <div class="equal-height col-lg-3 col-md-6 item">
          <div class="f-item link">
            <h4 class="widget-title">Services</h4>
            <ul>
              <li *ngFor="let service of serviceData">
                <a class="cursor-pointer" (click)="navigateService(service.service_id)">{{ service.service_title }}</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="equal-height col-lg-3 col-md-6 item">
          <!-- subscribe-form -->
          <div class="subscribe-area">
            <div class="row align-items-center">
              <div class="col-lg-12 col-md-6">
                <div class="title-style-four sm-pb-20">
                  <h4 class="main-title">
                    Join our <span>newsletter</span> & get updated.
                  </h4>
                </div>
              </div>
              <div class="col-lg-12 col-md-6">
                <div class="theme-basic-footer">
                  <div class="subscribe-form">
                    <form [formGroup]="subscribeForm">
                      <input type="email" placeholder="Enter your email" formControlName="subscriber_email" />
                      <div *ngIf="
                          valid['subscriber_email'].touched &&
                          valid['subscriber_email'].invalid
                        " class="show-error">
                        <div class="text-danger"
                          *ngIf="valid['subscriber_email'].errors?.['required'] && valid['subscriber_email'].dirty">
                          Please enter email address
                        </div>
                        <div class="text-danger"
                          *ngIf="valid['subscriber_email'].errors?.['email'] && valid['subscriber_email'].dirty">
                          Please enter a valid email address
                        </div>
                      </div>
                      <button [disabled]="subscribeForm.invalid" (click)="addSSubscribeNewsletter()">
                        Subscribe
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Contact Info -->
          <div class="f-item contact mt-5">
            <h4 class="widget-title">Contact Info</h4>
            <div class="address">
              <ul>
                <li><strong>Email : </strong> <span>
                    <a href="mailto:{{ contactsSpecificData?.contact_email }}">
                      {{ contactsSpecificData?.contact_email }}
                    </a>
                  </span></li>
                <li><strong>Contact : </strong>
                  <span>
                    <a href="tel:{{ contactsSpecificData?.contact_phone }}" class="call">
                      {{ contactsSpecificData?.contact_phone }}
                    </a>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-bottom">
      <div class="row">
        <!-- Disclaimer -->
        <div class="col-lg-6">
          <p>&copy; {{footerSpecificData?.footer_disclaimer}}</p>
        </div>
        <!-- Legal links -->
        <div class="col-lg-6 text-right link">
          <ul>
            <li *ngFor="let links of footerSpecificData?.footer_legal_links">
              <a routerLink="{{ links.legal_link_url }}">{{ links.title }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- Shape -->
  <div class="footer-shape" style="background-image: url(assets/img/shape/1.svg)"></div>
  <!-- End Shape -->
</footer>