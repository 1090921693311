import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  backendApiURL: any;
  constructor(private Http: HttpClient) {
    this.backendApiURL = environment.backendURL;
  }

  getHomeDetails() {
    return this.Http.get(this.backendApiURL + 'home').pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  addSubscribeNewsLetter(data: any) {
    return this.Http.put(this.backendApiURL + 'subscribe', data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  // ======================================================== Contact Us Page Related API's
  addContact(data: any) {
    return this.Http.put(this.backendApiURL + 'enquiry', data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  // ======================================================== Footer Related API's
  getFooterLinks() {
    return this.Http.get(this.backendApiURL + 'footer').pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  // ======================================================== About Us Page Realted API's
  getContactsData() {
    return this.Http.get(this.backendApiURL + 'contact').pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }
  getAboutus() {
    return this.Http.get(this.backendApiURL + 'aboutus').pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  // ======================================================== Careers Page Realted API's
  getAllActiveJobs() {
    return this.Http.get(this.backendApiURL + 'activejobs').pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }
  getJobbyId(job_id: any) {
    return this.Http.get(this.backendApiURL + 'job/' + job_id).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }
}
