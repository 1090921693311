import { ChangeDetectorRef, Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer } from '@angular/platform-browser';

import { ApiService } from '../shared/Services/api.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
})
export class ContactUsComponent {
  contactusForm!: FormGroup;
  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    public toastr: ToastrService,
    private sanitizer: DomSanitizer,
    private ref: ChangeDetectorRef
  ) {
    this.contactusForm = this.fb.group({
      enquiry_name: [
        '',
        [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)],
      ],
      enquiry_subject: ['', [Validators.required]],
      enquiry_message: ['', [Validators.required]],
      enquiry_email: ['', [Validators.required, Validators.email]],
    });
  }

  ngOnInit(): void {
    this.getContacts();
  }

  contactusFormResponse: any;
  addContactForm() {
    // console.log("this.contactusForm.value",this.contactusForm.value);
    this.apiService.addContact(this.contactusForm.value).subscribe({
      next: (val: any) => {
        this.contactusFormResponse = val;
        if (this.contactusFormResponse.status === 1) {
          this.toastr.success(
            'Thanks for contacting Us. We appreciate your time. Our support team will contact you at the earliest.'
          );
          this.contactusForm.reset();
        }
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => {},
    });
  }

  get valid() {
    return this.contactusForm.controls;
  }

  contactsData: any;
  contactsSpecificData: any;
  getContacts() {
    this.apiService.getContactsData().subscribe({
      next: (val: any) => {
        this.contactsData = val;
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => {
        if (this.contactsData.status === 1) {
          this.contactsSpecificData = this.contactsData.contacts[0];
          this.getUrl(this.contactsSpecificData?.google_map_src);
        }
      },
    });
  }

  safeUrl: any;
  getUrl(srcURL: any) {
    return (this.safeUrl =
      this.sanitizer.bypassSecurityTrustResourceUrl(srcURL));
  }
}
