import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';

import { ApiService } from '../shared/Services/api.service';
import { SolutionsService } from '../shared/Services/solutions.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent {
  constructor(
    private apiService: ApiService,
    private servicesApi: SolutionsService,
    private route: Router
  ) {}

  ngOnInit(): void {
    this.getHomeData();
  }

  homeApiData: any;
  homeData: any;
  home_loader: boolean = false;
  sectionsAllArray: any = [];
  stripArrayData: any = [];
  getHomeData() {
    this.apiService.getHomeDetails().subscribe({
      next: (val: any) => {
        this.homeApiData = val;
        // console.log(this.homeApiData);
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => {
        if (this.homeApiData.status === 1) {
          this.homeData = this.homeApiData.template;
          this.home_loader = true;
          this.getAllServices();
          // console.log('HOME DATA', this.homeData);
          this.sectionsAllArray = this.homeData?.home_sections_data.sort(
            (a: any, b: any) => a.section_order - b.section_order
          );
          this.stripArrayData = this.homeData?.home_strips_data;
        }
      },
    });
  }
  
  servicesApiData: any;
  serviceData: any;
  getAllServices() {
    this.servicesApi.getAllServices().subscribe({
      next: (val: any) => {
        // console.log(val);
        this.servicesApiData = val;
      },
      error: (err: any) => {},
      complete: () => {
        if (this.servicesApiData.status === 1) {
          // this.serviceData = this.servicesApiData.services;
          this.serviceData = this.servicesApiData.services.sort(
            (a: any, b: any) => a.service_order - b.service_order
          );
        }
      },
    });
  }

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    margin: 10,
    autoplay: true,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      740: {
        items: 3,
      },
      940: {
        items: 3,
      },
    },
    nav: true,
  };

  navigateService(service_id: any) {
    this.route.navigate(['services', service_id]);
  }
}
