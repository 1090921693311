<footer>
  <div class="container-fluid">
    <div class="f-items default-padding">
      <div class="row">
        <div class="equal-height col-lg-4 col-md-6 item">
          <div class="f-item about">
            <img [src]="footerSpecificData?.footer_logo" alt="Logo" width="200" />
            <p [innerHTML]="footerSpecificData?.footer_description"></p>
          </div>
        </div>

        <div class="equal-height col-lg-2 col-md-6 item">
          <div class="f-item link">
            <h4 class="widget-title">Quick Links</h4>
            <ul>
              <li *ngFor="let links of footerSpecificData?.footer_quick_links">
                <a routerLink="{{ links.quick_link_url }}">{{ links.title }}</a>
              </li>
            </ul>
          </div>
        </div>

        <div class="equal-height col-lg-3 col-md-6 item">
          <div class="f-item link">
            <h4 class="widget-title">Services</h4>
            <ul>
              <li *ngFor="let service of serviceData">
                <a class="cursor-pointer" (click)="navigateService(service.service_id)">{{ service.service_title }}</a>
              </li>
            </ul>
          </div>
        </div>

        <div class="equal-height col-lg-3 col-md-6 item">
          <div class="f-item contact">
            <h4 class="widget-title">Contact Info</h4>

            <div class="address">
              <ul>
                <li><strong>Email:</strong> support@cliffitsolutions.com</li>
                <li><strong>Contact:</strong> +763 710 5064</li>
              </ul>
            </div>
            <ul class="social">
              <li class="facebook" *ngFor="let social of footerSpecificData?.footer_social_links">
                <a href="{{ social.title }}" *ngIf="social.image_url !== ''" target="_blank">
                  <img [src]="social.image_url" [alt]="social.title" /></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-bottom">
      <div class="row">
        <div class="col-lg-6">
          <p>&copy; Copyright 2024. Cliff IT Solutions.</p>
        </div>
        <div class="col-lg-6 text-right link">
          <ul>
            <li *ngFor="let links of footerSpecificData?.footer_legal_links">
              <a routerLink="{{ links.legal_link_url }}">{{ links.title }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- Shape -->
  <div class="footer-shape" style="background-image: url(assets/img/shape/1.svg)"></div>
  <!-- End Shape -->
</footer>