import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { ApiService } from 'src/app/shared/Services/api.service';

@Component({
  selector: 'app-job-list',
  templateUrl: './job-list.component.html',
})
export class JobListComponent {
  constructor(private apiService: ApiService, private router: Router) {}

  ngOnInit(): void {
    this.getAllActiveJobs();
  }

  activeJobData: any;
  joblistData: any;
  getAllActiveJobs() {
    this.apiService.getAllActiveJobs().subscribe({
      next: (val: any) => {
        console.log(val);
        this.activeJobData = val;
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => {
        if (this.activeJobData.status === 1) {
          this.joblistData = this.activeJobData.jobs;
        }
      },
    });
  }
  navigatetoDetail(job_id: any) {
    this.router.navigate(['careers', job_id]);
  }
}
