<header id="home">
  <!-- Start Navigation -->
  <nav
    class="navbar navbar-default navbar-fixed-light attr-border navbar-transparent navbar-fixed dark no-background bootsnav bg-gray">
    <!-- Start Top Search -->
    <div class="container-fluid">
      <div class="row">
        <div class="top-search">
          <div class="input-group">
            <form action="#">
              <input type="text" name="text" class="form-control" placeholder="Search" />
              <button type="submit">
                <i class="ti-search"></i>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- End Top Search -->

    <div class="container-fluid">

      <!-- Start Header Navigation -->
      <div class="navbar-header">
        <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#navbar-menu">
          <i class="fa fa-bars"></i>
        </button>
        <a class="navbar-brand" routerLink="/">
          <img src="assets/img/logo-white.png" class="logo" alt="Logo" width="200" />
        </a>
      </div>
      <!-- End Header Navigation -->

      <!-- Collect the nav links, forms, and other content for toggling -->
      <div class="collapse navbar-collapse" id="navbar-menu">
        <ul class="nav navbar-nav navbar-right" data-in="fadeInDown" data-out="fadeOutUp">
          <li> <a routerLink="/">Home</a> </li>
          <li> <a routerLink="/about-us">About us</a> </li>

          <li class="dropdown">
            <a class="dropdown-toggle" data-toggle="dropdown">Services</a>
            <ul class="dropdown-menu">
              <li *ngFor="let services of serviceData; let i = index">
                <a (click)="navigateService(services.service_id)" class="dropdown-item cursor-pointer"
                  data-toggle="collapse" data-target="#navbar-menu"><span>{{ services.service_title }}</span></a>
              </li>
            </ul>
          </li>

          <li> <a routerLink="/partnership">Partnership</a> </li>
          <li> <a routerLink="/contact-us">Contact</a> </li>
          <li> <a routerLink="/careers">Career</a> </li>
        </ul>
      </div>
      <!-- /.navbar-collapse -->

    </div>
  </nav>
  <!-- End Navigation -->
</header>