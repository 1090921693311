import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';

import { SolutionsService } from '../shared/Services/solutions.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent {
  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    let element = document.querySelector('.navbar') as HTMLElement;

    if (window?.pageYOffset > element.clientHeight) {
      element.classList.add('bg-primary-color');
    } else {
      element.classList.remove('bg-primary-color');
    }
  }
  constructor(private servicesApi: SolutionsService, private route: Router) {}

  ngOnInit(): void {
    this.getAllServices();
  }

  servicesApiData: any;
  serviceData: any;
  getAllServices() {
    this.servicesApi.getAllServices().subscribe({
      next: (val: any) => {
        // console.log(val);
        this.servicesApiData = val;
      },
      error: (err: any) => {},
      complete: () => {
        if (this.servicesApiData.status === 1) {
          this.serviceData = this.servicesApiData.services.sort(
            (a: any, b: any) => a.service_order - b.service_order
          );
        }
      },
    });
  }

  navigateService(service_id: any) {
    this.route.navigate(['services', service_id]);
  }
}
