<!-- Start Breadcrumb 
    ============================================= -->
<div class="breadcrumb-area gradient-bg text-light text-center">
  <!-- Fixed BG -->
  <div class="fixed-bg" style="background-image: url(assets/img/shape/1.png)"></div>
  <!-- Fixed BG -->
  <div class="container">
    <div class="row">
      <div class="col-lg-8 offset-lg-2">
        <h1>{{ singJobData?.job_title }}</h1>
        <ul class="breadcrumb">
          <li>
            <a routerLink="/"><i class="fas fa-home"></i> Home</a>
          </li>
          <li>
            <a routerLink="/careers">Careers</a>
          </li>
          <li class="active">{{ singJobData?.job_title }}</li>
        </ul>
      </div>
    </div>
  </div>
</div>
<!-- End Breadcrumb -->

<div class="blog-area single full-blog right-sidebar full-blog default-padding">
  <div class="container">
    <div class="blog-items">
      <div class="row">
        <div class="blog-content col-lg-8 col-md-12">
          <div class="item">
            <div class="blog-item-box">
              <div class="info">
                <h4>{{ singJobData?.job_title }}</h4>
                <div class="bottom-jobs-details">
                  <p class="mb-2">
                    <img src="assets/images/icon/icon_06.svg" width="20" alt="" class="d-inline" />
                    Indore {{ singJobData?.job_location }}
                  </p>
                  <span>Posted: 23/233
                    <span class="text-white">{{
                      singJobData?.job_updated_on
                      }}</span></span>
                  <span>Openings: 10
                    <span class="text-white">{{
                      singJobData?.job_openings
                      }}</span></span>
                  <span>Applicants: 2
                    <span class="text-white">{{
                      singJobData?.job_application_count
                      }}</span></span>
                </div>
                <p [innerHTML]="singJobData?.job_description"></p>
                <span class="tags pt-10" *ngFor="let type of singJobData?.job_type">
                  <span> {{ type }}, </span>
                </span>
                <p class="pt-10 mb-0">
                  {{ singJobData?.job_skill_requirements }}
                </p>
                <p class="pt-10 mb-0">
                  {{ singJobData?.job_note }}
                </p>
                <p class="pt-10" [innerHTML]="singJobData?.job_description"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>