import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class handleErrorService {
  constructor(public toastr: ToastrService) { }
  public handleError(err: HttpErrorResponse) {
    let errorMessage: string;
    if (err.error instanceof ErrorEvent) {
      errorMessage = `Please Choose Your Network ${err.error.message}`;
    } else {
      switch (err.status) {
        case 400:
          errorMessage = `${err.status}: Bad Request`;
          break;
        case 401:
          errorMessage = `${err.status}: You are Unauthorized`;
          break;
        case 403:
          errorMessage = `${err.status}: You don't have permission to access the requested resource`;
          break;
        case 404:
          errorMessage = `${err.status}: The requested resource doesn't exist`;
          break;
        case 500:
          errorMessage = `${err.status}: Internal Server Error`;
          break;
        case 503:
          errorMessage = `${err.status}: The requested service is not available`;
          break;
        default:
          errorMessage = `something went wrong`;
      }
    }
    this.toastr.error(errorMessage);
  }
}
