<ng-container *ngIf="service_loader == false; else apiData">
  <app-loader></app-loader></ng-container>
<ng-template #apiData>

  <!-- Start Breadcrumb ============================================= -->
  <div class="breadcrumb-area gradient-bg text-light">
    <!-- Fixed BG -->
    <div class="fixed-bg" style="background-image: url(assets/img/shape/1.png)"></div>
    <!-- Fixed BG -->
    <div class="container-fluid">
      <div class="row align-center">
        <div class="col-lg-2"></div>
        <div class="col-lg-8">
          <div class="info wow fadeInLeft">
            <h1>{{ serviceData?.service_banner_title }}</h1>
            <p *ngIf="serviceData?.service_banner_sub_desc !== ''" [innerHTML]="serviceData?.service_banner_sub_desc">
            </p>
            <a *ngIf="serviceData?.service_banner_link.hasOwnProperty('link')"
              routerLink="{{ serviceData?.service_banner_link?.link }}" class="btn-simple md mt-3"><i
                class="fas fa-angle-right"></i>
              {{ serviceData?.service_banner_link?.title }}</a>
          </div>
        </div>
        <div class="col-lg-2">
          <!-- <div class="thumb wow fadeInUp" data-wow-delay="400ms">
            <img [src]="serviceData?.service_banner_image" alt="Thumb" class="img-fluid" />
          </div> -->
        </div>
      </div>
    </div>
  </div>
  <!-- End Breadcrumb -->

  <!-- Start Services
    ============================================= -->
  <div class="services-area text-center carousel-shadow wavesshape-bottom default-padding">
    <div class="container-fluid">
      <div class="about-items">
        <div class="row text-left">
          <div class="col-lg-12 text-center mb-5">
            <h2 class="">{{ serviceData?.service_sub_service_title }}</h2>
            <p [innerHTML]="serviceData?.service_sub_service_sub_description"></p>
          </div>
          <div class="feature-box text-left col-lg-12 pt-5">
            <div class="row">
              <!-- Single Item -->
              <div class="col-lg-4 col-md-6 mb-3" *ngFor="let slide of serviceData?.service_sub_services_data">
                <div class="info borders p-4">
                  <h4>{{ slide.sub_service_title }}</h4>
                  <p class="text-four-line-restrict" [innerHTML]="slide.sub_service_description"></p>
                </div>
              </div>
              <!-- End Single Item -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End Services -->

  <!-- Section 1 start -->
  <!-- <div class="about-area bg-gray overflow-hidden rectangular-shape"
    *ngFor="let sections of sectionsSrotedData; let even = even">
    <div class="container-fluid" *ngIf="even">
      <div class="about-items choseus-items right-thumb default-padding">
        <div class="row align-center">
          <div class="col-lg-6 mb-3">
            <div class="info wow fadeInLeft">
              <p [innerHTML]="sections.section_description"></p>
              <h2>{{ sections.section_title }}</h2>
              <p *ngIf="sections.section_sub_description !== ''" [innerHTML]="sections.section_sub_description"></p>
              <div class="my-3" *ngIf="sections.section_tags.length !== 0">
                <span class="btn-tags" *ngFor="let tags of sections.section_tags; let i = index">
                  {{ tags }}
                </span>
              </div>
              <ul *ngIf="sections.section_bullets.length !== 0">
                <li *ngFor="let bullets of sections.section_bullets">
                  <span><i class="ti-check"></i> {{ bullets }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-6 mb-3">
            <div class="thumb wow fadeInUp" data-wow-delay="0.5s">
              <img src="assets/img/illustration/9.png" alt="Thumb" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="start-us-area overflow-hidden bg-gradient text-light default-padding" *ngIf="!even">

      <div class="container">
        <div class="row align-center">
          <div class="col-lg-6 mb-3">
            <div class="thumb wow fadeInUp" data-wow-delay="400ms">
              <img src="assets/img/illustration/7.png" alt="Thumb" class="img-fluid" />
            </div>
          </div>
          <div class="col-lg-6 mb-3">
            <div class="info wow fadeInLeft">
              <p [innerHTML]="sections.section_description"></p>
              <h2>{{ sections.section_title }}</h2>
              <p *ngIf="sections.section_sub_description !== ''" [innerHTML]="sections.section_sub_description"></p>
              <div class="my-3" *ngIf="sections.section_tags.length !== 0">
                <span class="btn-tags" *ngFor="let tags of sections.section_tags; let i = index">
                  {{ tags }}
                </span>
              </div>
              <ul class="my-3" *ngIf="sections.section_bullets.length !== 0">
                <li class="my-3" *ngFor="let bullets of sections.section_bullets">
                  <span class="font-bold"><i class="ti-check"></i> &nbsp;{{ bullets }}</span>
                </li>
              </ul>
              <a class="btn-standard md" routerLink="{{ links.link }}" *ngFor="let links of sections.section_links">{{
                links.title }} <i class="fas fa-angle-right"></i></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <!-- Section 1 end  -->

  <!-- Section 1 start -->
  <div class="about-area bg-gray overflow-hidden rectangular-shape" *ngIf="sectionsSrotedData.length !== 0">
    <div class="container-fluid">
      <div class="about-items choseus-items right-thumb default-padding">
        <div class="row align-center">
          <div class="col-lg-8 mb-3">
            <h2>Tailored solutions designed for today's enterprises.</h2>
            <p>
              Regardless of your current stage or industry, whether you're handling extensive data or constructing
              advanced AI models, Cliff is ready to support you. Through our cutting-edge solutions, you can leverage
              the potential of data and AI to achieve quicker, predictive, and proactive decision-making, while also
              guiding your organization on the journey ahead.
            </p>
          </div>
          <div class="col-lg-4 mb-3"></div>
          <div class="col-lg-12 mb-3">
            <div class="info wow fadeInLeft">
              <div class="accordion" id="accordionExample">
                <!-- <div class="card bg-primary-color" *ngFor="let sections of sectionsSrotedData; let i = index">
                  <div class="card-header" id="headingOne">
                    <div [ngClass]="
                        i === 0
                          ? ' text-underline-none text-white d-flex justify-content-between'
                          : 'text-underline-none text-white collapsed d-flex justify-content-between'
                      " type="button" data-toggle="collapse" [attr.data-target]="'#collapse' + i" aria-expanded="true"
                      aria-controls="collapseOne">
                      <h2 class="mb-0">
                        <button [ngClass]="
                            i === 0
                              ? 'btn btn-link p-0 text-underline-none text-white'
                              : 'btn btn-link p-0 text-underline-none text-white collapsed'
                          " type="button" data-toggle="collapse" [attr.data-target]="'#collapse' + i"
                          aria-expanded="true" aria-controls="collapseOne">
                          {{ sections.section_title }}
                        </button>
                      </h2>
                      <p>
                        <i class="fa fa-chevron-down"></i>
                      </p>
                    </div>
                  </div>

                  <div id="collapse{{ i }}" class="collapse" aria-labelledby="headingOne"
                    data-parent="#accordionExample">
                    <div class="card-body">
                      <p *ngIf="sections.section_sub_description !== ''" [innerHTML]="sections.section_sub_description">
                      </p>
                      <div class="my-3" *ngIf="sections.section_tags.length !== 0">
                        <span class="btn-tags" *ngFor="
                            let tags of sections.section_tags;
                            let i = index
                          ">
                          {{ tags }}
                        </span>
                      </div>
                      <ul *ngIf="sections.section_bullets.length !== 0">
                        <li *ngFor="let bullets of sections.section_bullets">
                          <span><i class="ti-check"></i> {{ bullets }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div> -->
                <div class="card cursor-pointer mb-3" *ngFor="let sections of sectionsSrotedData; let i = index">
                  <div class="card-header" id="headingTwo">
                    <h4 class="mb-0 collapsed" data-toggle="collapse" [attr.data-target]="'#collapse' + i"
                      aria-expanded="false" [attr.aria-controls]="'#collapse' + i">
                      {{ sections.section_title }}
                    </h4>
                  </div>
                  <div [id]="'collapse' + i" class="collapse" aria-labelledby="headingTwo"
                    data-parent="#accordionExample">
                    <div class="card-body">
                      <p *ngIf="sections.section_sub_description !== ''" [innerHTML]="sections.section_sub_description">
                      </p>
                      <div class="my-3" *ngIf="sections.section_tags.length !== 0">
                        <span class="btn-tags" *ngFor="
                            let tags of sections.section_tags;
                            let i = index
                          ">
                          {{ tags }}
                        </span>
                      </div>
                      <ul *ngIf="sections.section_bullets.length !== 0">
                        <li *ngFor="let bullets of sections.section_bullets">
                          <span><i class="ti-check"></i> {{ bullets }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Section 1 end  -->

  <!-- Start Partners Area
    ============================================= -->
  <div class="blog-area default-padding bottom-less">
    <div class="container-fluid">
      <div class="heading-left">
        <div class="row">
          <div class="col-lg-5">
            <h2>
              The collaborative network formed by our partners and alliances.
            </h2>
          </div>
          <div class="col-lg-6 offset-lg-1">
            <p>
              We establish partnerships and strategic alliances with top-tier organizations globally, aiming to broaden
              our service portfolio and offer holistic solutions to clients. With more than 85 strategic alliances
              formed with industry leaders, we are equipped to deliver comprehensive solutions addressing both your
              business and IT challenges.
            </p>
          </div>
        </div>
      </div>
      <div class="blog-items">
        <div class="row">
          <!-- Single Item -->
          <div class="col-lg-3 col-md-6 single-item">
            <div class="item">
              <div class="thumb bg-white p-3">
                <img src="assets/img/clients/logo-microsoft-1.svg" alt="Thumb" />
              </div>
            </div>
          </div>
          <!-- End Single Item -->
          <!-- Single Item -->
          <div class="col-lg-3 col-md-6 single-item">
            <div class="item">
              <div class="thumb bg-white p-3 text-center">
                <img src="assets/img/clients/aws-icon.svg" alt="Thumb" height="64" />
              </div>
            </div>
          </div>
          <!-- End Single Item -->
          <!-- Single Item -->
          <div class="col-lg-3 col-md-6 single-item">
            <div class="item">
              <div class="thumb bg-white px-3 py-4">
                <img src="assets/img/clients/google-cloud-1.svg" alt="Thumb" />
              </div>
            </div>
          </div>
          <!-- End Single Item -->
        </div>
      </div>
    </div>
  </div>
  <!-- End Partners Area -->

  <!-- Start Services Area
    ============================================= -->
  <div class="blog-area default-padding bg-gray bottom-less">
    <div class="container-fluid">
      <div class="heading-left">
        <div class="row">
          <div class="col-lg-5">
            <h2>Discover additional service offerings.</h2>
          </div>
          <div class="col-lg-6 offset-lg-1">
            <p>
              Discover additional services designed to elevate data and AI capabilities within your business:
            </p>
          </div>
        </div>
      </div>
      <div class="blog-items">
        <div class="row">
          <!-- Single Item -->
          <div class="col-lg-4 col-md-6 single-item" *ngFor="let services of relatedServices">
            <div class="item">
              <!-- <div class="thumb">
                <img
                  [src]="services.service_image"
                  [alt]="services.service_title"
                />
              </div> -->
              <div class="info">
                <h4>
                  {{ services.service_title }}
                </h4>
                <p class="text-four-line-restrict" [innerHTML]="services.service_description"></p>
                <a class="btn-simple cursor-pointer" (click)="navigatetoDetail(services.service_id)"><i
                    class="fas fa-angle-right"></i> Read More</a>
              </div>
            </div>
          </div>
          <!-- End Single Item -->
        </div>
      </div>
    </div>
  </div>
  <!-- End Services Area -->
</ng-template>