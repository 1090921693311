import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// Load Google Analytics script dynamically based on the environment
const gtagScript = document.createElement('script');
gtagScript.src = environment.production
  ? 'https://www.googletagmanager.com/gtag/js?id=G-EV0SNN22DK'
  : 'https://www.googletagmanager.com/gtag/js?id=G-0S4NGNBHGC';
document.head.appendChild(gtagScript);

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
