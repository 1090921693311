<div class="breadcrumb-area gradient-bg text-light">
  <!-- Fixed BG -->
  <div class="fixed-bg" style="background-image: url(assets/img/shape/1.png)"></div>
  <!-- Fixed BG -->
  <div class="container-fluid">
    <div class="row align-center">
      <div class="col-lg-8">
        <div class="info wow fadeInLeft">
          <h1>
            Collaborating with industry leaders and technology giants for your
            growth
          </h1>
          <p>
            Achieving organizational success requires a thriving partner ecosystem. We understand the power of the right
            network to expedite your go-to-market, foster innovation, and enhance service delivery. We deliver value at
            scale, partnering with the leading cloud and technology platforms, and ensure you succeed.
          </p>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="thumb wow fadeInUp" data-wow-delay="400ms">
          <img src="assets/img/partner.jpg" alt="Thumb" class="img-fluid br-12" />
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Section 1 end  -->

<!-- section 2 start -->
<div class="about-area bg-gray overflow-hidden rectangular-shape default-padding">
  <div class="container-fluid">
    <div class="about-items choseus-items right-thumb">
      <div class="row align-center">
        <div class="col-lg-6">
          <div class="thumb wow fadeInUp" data-wow-delay="0.5s">
            <!-- <img src="assets/img/illustration/7.png" alt="Thumb" class="img-fluid" /> -->
            <img src="assets/img/partnership.png" alt="Thumb" class="br-12" />
          </div>
        </div>
        <div class="col-lg-6">
          <div class="info wow fadeInLeft">
            <h2>Cloud partnerships</h2>
            <p>
              Through 360° partnerships with industry-leading hyperscalers, and niche technology players, CLIFF IT
              Solutions Analytics has a strong partner ecosystem. We co-develop innovative solutions and cultivate
              specialized vertical capabilities to unlock unparalleled possibilities for our clients.
            </p>
            <ul>
              <li>
                <span><i class="ti-check"></i> MICROSOFT AZURE</span>
              </li>
              <li>
                <span><i class="ti-check"></i> GOOGLE CLOUD</span>
              </li>
              <li>
                <span><i class="ti-check"></i> AWS</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>