<!-- Start Breadcrumb 
    ============================================= -->
<div class="breadcrumb-area gradient-bg text-light text-center">
  <!-- Fixed BG -->
  <div class="fixed-bg" style="background-image: url(assets/img/shape/1.png)"></div>
  <!-- Fixed BG -->
  <div class="container">
    <div class="row">
      <div class="col-lg-8 offset-lg-2">
        <h1>Careers</h1>
        <ul class="breadcrumb">
          <li>
            <a routerLink="/"><i class="fas fa-home"></i> Home</a>
          </li>
          <li class="active">Careers</li>
        </ul>
      </div>
    </div>
  </div>
</div>
<!-- End Breadcrumb -->
<!--  -->
<div class="blog-area full-blog blog-standard full-blog default-padding">
  <div class="container">
    <div class="blog-items">
      <div class="blog-content">
        <div class="blog-item-box">
          <div class="row">
            <div class="col-lg-12">
              <ng-container *ngIf="joblistData.length == 0; else jobListings">
                <article class="blog-meta-one color-two tran3s cursor-pointer">
                  <div class="post-data">
                    <h5>
                      Thank You for showing interest at Cliff IT Data & AI
                      Solutions in your professional Journey.
                      <br />Currently we have no openings. Visit us again or
                      subscribe to our site for constant updates on what we are
                      up to!
                    </h5>
                  </div>
                </article>
              </ng-container>
            </div>
            <!-- Single Item -->
            <ng-template #jobListings>
              <div class="col-lg-4 col-md-6 single-item" *ngFor="let jobs of joblistData">
                <div class="item cursor-pointer">
                  <!-- <div class="thumb">
                    <a href="#">
                      <img src="assets/img/blog/1.jpg" alt="Thumb" />
                      <div class="date">18 Jul <strong>2020</strong></div>
                    </a>
                  </div> -->
                  <div class="info">
                    <h4>
                      <a href="#">{{ jobs.job_title }}</a>
                    </h4>
                    <p class="text-four-line-restrict" [innerHTML]="jobs.job_description"></p>
                    <a class="btn-simple" (click)="navigatetoDetail(jobs.job_id)"><i class="fas fa-angle-right"></i>
                      Read More</a>
                  </div>
                </div>
              </div>
            </ng-template>
            <!-- End Single Item -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <div class="blog-section-three mb-150 pt-90 lg-pt-40 lg-mb-100">
  <div class="container">
    <div class="row gx-xxl-5">
      <ng-container *ngIf="joblistData.length === 0; else jobListings">
        <div class="col-lg-12 col-sm-12" data-aos="fade-up">
          <article class="blog-meta-one color-two tran3s cursor-pointer">
            <div class="post-data">
              <h5>
                Thank You for showing interest at Cliff IT & Data Solutions in
                your professional Journey.
                <br />Currently we have no openings. Visit us again or subscribe
                to our site for constant updates on what we are up to!
              </h5>
            </div>
          </article>
        </div>
      </ng-container>
      <ng-template #jobListings>
        <div
          class="col-lg-4 col-sm-6 d-flex"
          data-aos="fade-up"
          *ngFor="let jobs of joblistData"
        >
          <article
            class="blog-meta-one color-two tran3s mt-45 cursor-pointer"
            (click)="navigatetoDetail(jobs.job_id)"
          >
            <div class="post-data">
              <h5>{{ jobs.job_title }}</h5>
              <p>{{ jobs.job_skill_requirements }}</p>
              <div class="post-tag fs-6">
                {{ jobs.job_updated_on }}
              </div>
            </div>
          </article>
        </div>
      </ng-template>
    </div>
    
  </div>
</div> -->
<!-- Job List end -->