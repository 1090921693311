import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ApiService } from 'src/app/shared/Services/api.service';

@Component({
  selector: 'app-job-detail',
  templateUrl: './job-detail.component.html',
})
export class JobDetailComponent {
  constructor(
    private apiService: ApiService,
    private activatedRouter: ActivatedRoute
  ) {}

  job_id: any;
  ngOnInit(): void {
    this.job_id = this.activatedRouter.snapshot.params['id'];
    this.getJobbyID(this.job_id);
  }

  jobData: any;
  singJobData: any;
  getJobbyID(job_id: any) {
    this.apiService.getJobbyId(job_id).subscribe({
      next: (val: any) => {
        this.jobData = val;
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => {
        if (this.jobData.status === 1) {
          this.singJobData = this.jobData.job[0];
        }
      },
    });
  }
}
