import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

import { environment } from 'src/environments/environment';

import { SolutionsService } from '../shared/Services/solutions.service';
import { ApiService } from '../shared/Services/api.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent {
  subscribeForm!: FormGroup;
  constructor(
    private fb: FormBuilder,
    private ApiService: ApiService,
    public toastr: ToastrService,
    private servicesApi: SolutionsService,
    private route: Router
  ) {
    this.subscribeForm = this.fb.group({
      subscriber_email: ['', [Validators.required, Validators.email]],
    });
  }

  get valid() {
    return this.subscribeForm.controls;
  }
  ngOnInit(): void {
    this.getFooterLinks();
    this.getAllServices();
  }

  subscribeFormResponse: any;
  addSSubscribeNewsletter() {
    this.ApiService.addSubscribeNewsLetter(this.subscribeForm.value).subscribe({
      next: (val: any) => {
        this.subscribeFormResponse = val;
        if (this.subscribeFormResponse.status === 1) {
          this.toastr.success(
            'Thank you for subscribing to CLIFF. Newsletters.'
          );
          this.subscribeForm.reset();
        }
        if (this.subscribeFormResponse.status === 0) {
          if (this.subscribeFormResponse.message == 'Already a subscriber') {
            this.toastr.info(
              'This email address is already subscribed to the newsletters'
            );
            this.subscribeForm.reset();
          }
        }
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => {},
    });
  }

  footerData: any;
  footerSpecificData: any;
  footerApiResponse: boolean = false;
  getFooterLinks() {
    this.ApiService.getFooterLinks().subscribe({
      next: (val: any) => {
        this.footerData = val;
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: async () => {
        if (this.footerData.status === 1) {
          this.footerSpecificData = await this.footerData.footer[0];
          this.footerSpecificData.cloudFront_url = environment.cloudFront_url;
          this.footerApiResponse = true;
          // console.log(this.footerSpecificData);
        }
      },
    });
  }

  servicesApiData: any;
  serviceData: any;
  serviceApiResponse: boolean = false;
  getAllServices() {
    this.servicesApi.getAllServices().subscribe({
      next: (val: any) => {
        // console.log(val);
        this.servicesApiData = val;
      },
      error: (err: any) => {},
      complete: () => {
        if (this.servicesApiData.status === 1) {
          this.serviceData = this.servicesApiData.services.sort(
            (a: any, b: any) => a.service_order - b.service_order
          );
          this.serviceApiResponse = true;
        }
      },
    });
  }

  navigateService(service_id: any) {
    this.route.navigate(['services', service_id]);
  }
}
