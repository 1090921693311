import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, NgZone, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import * as AOS from 'aos';
import { filter } from 'rxjs';
import { environment } from 'src/environments/environment';

declare var gtag: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'cliffwebsite';
  isBrowser = false;

  constructor(@Inject(PLATFORM_ID) private platformId: any, private route: Router,
    private ngZone: NgZone,
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);

    // G-tag related //
    this.route.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if (isPlatformBrowser(this.platformId)) {
          this.ngZone.runOutsideAngular(() => {
            if (environment.production) {
              gtag("config", "G-EV0SNN22DK", {
                page_path: event.urlAfterRedirects,
              });
            } else {
              gtag("config", "G-0S4NGNBHGC", {
                page_path: event.urlAfterRedirects,
              });
            }
          });
        }
      });
    // G-tag related //
  }

  ngOnInit() {
    // if (this.isBrowser) {
    //   AOS.init(); //AOS - 2
    //   AOS.refresh(); //refresh method is called on window resize and so on, as it doesn't require to build new store with AOS elements and should be as light as possible.
    // }
  }
}
