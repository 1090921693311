<!-- Start Breadcrumb 
    ============================================= -->
<div class="breadcrumb-area gradient-bg text-light text-center">
  <!-- Fixed BG -->
  <div class="fixed-bg" style="background-image: url(assets/img/shape/1.png)"></div>
  <!-- Fixed BG -->
  <div class="container">
    <div class="row">
      <div class="col-lg-8 offset-lg-2">
        <h1>Careers</h1>
        <ul class="breadcrumb">
          <li> <a routerLink="/"><i class="fas fa-home"></i> Home</a> </li>
          <li class="active">Careers</li>
        </ul>
      </div>
    </div>
  </div>
</div>
<!-- End Breadcrumb -->

<!--  -->
<div class="blog-area full-blog blog-standard full-blog default-padding bg-gray">
  <div class="container">
    <div class="blog-items">
      <div class="blog-content">
        <div class="blog-item-box">

          <!-- Empty Job List Start -->
          <div class="row">
            <div class="col-lg-12">
              <ng-container *ngIf="joblistData.length == 0; else jobListings">
                <article class="blog-meta-one color-two tran3s cursor-pointer">
                  <div class="post-data">
                    <h5>
                      Thank You for showing interest at Cliff IT Data & AI Solutions in your professional Journey.
                      <br />Currently we have no openings. Visit us again or subscribe to our site for constant updates
                      on what we are up to!
                    </h5>
                  </div>
                </article>
              </ng-container>
            </div>
          </div>
          <!-- Empty Job List End -->

          <!-- Job List Start -->
          <ng-template #jobListings>
            <div class="row">
              <div class="col-lg-6 col-md-6 single-item" *ngFor="let jobs of joblistData">
                <!-- Job Card Start -->
                <div class="item cursor-pointer">
                  <div class="info bg-dark">
                    <h4 class="wow fadeInDown banner-heading" data-wow-duration="1s" style="opacity: 1"> {{
                      jobs.job_title }} </h4>
                    <p class="text-four-line-restrict wow fadeInLeft" [innerHTML]="jobs?.job_description"></p>
                    <div class="fs-6 wow fadeInLeft mb-3">Updated On:
                      <span class="fw-bold"> {{ jobs?.job_updated_on | date:'mediumDate' }} </span>
                    </div>
                    <a class="btn circle btn-md btn-gradient wow fadeInUp" data-wow-duration="1.8s"
                      (click)="navigatetoDetail(jobs?.job_id)"> View and Apply <i class="fas fa-angle-right"></i></a>
                  </div>
                </div>
                <!-- Job Card End -->
              </div>
            </div>
          </ng-template>
          <!-- Job List End -->
        </div>
      </div>
    </div>
  </div>
</div>