<!-- Start Breadcrumb 
    ============================================= -->
<div class="breadcrumb-area gradient-bg text-light text-center">
  <!-- Fixed BG -->
  <div class="fixed-bg" style="background-image: url(assets/img/shape/1.png)"></div>
  <!-- Fixed BG -->
  <div class="container">
    <div class="row">
      <div class="col-lg-8 offset-lg-2">
        <h1>{{ singJobData?.job_title }}</h1>
        <ul class="breadcrumb">
          <li> <a routerLink="/"><i class="fas fa-home"></i> Home</a> </li>
          <li> <a routerLink="/careers">Careers</a> </li>
          <li class="active">{{ singJobData?.job_title }}</li>
        </ul>
      </div>
    </div>
  </div>
</div>
<!-- End Breadcrumb -->

<div class="blog-area single full-blog right-sidebar full-blog default-padding bg-gray">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="card br-12">
          <div class="card-body">
            <div class="d-flex flex-column align-items-center text-center">
              <div class="mt-3">
                <h2>{{singJobData?.job_title}}</h2>
                <ng-container *ngIf="singJobData?.job_subtitle !== ''">
                  <h6 class="mb-3">{{singJobData?.job_subtitle}}</h6>
                </ng-container>
                <ng-container *ngIf="singJobData?.job_location !== ''">
                  <h6><i class="fa fa-map-marker fs-5 mb-3"></i>Location : {{singJobData?.job_location}}</h6>
                </ng-container>
                <div class="mb-3 mt-3">
                  <div class="chip bg-dark shadow border border-danger txt-light"
                    *ngFor="let tag of singJobData?.job_type">
                    <span class="text-warning">#</span> {{ tag }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-3 text-center">
                <h6 class="mb-0">Job Status:
                  <span class="fw-bold" [ngClass]="singJobData?.job_status == 1 ? 'text-success':'text-danger'">
                    {{singJobData?.job_status == 1 ? "Active" : "InActive" }}</span>
                </h6>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 text-center mb-3">
                <h6 class="mb-0">Openings:
                  <span class="fw-bold text-primary">{{singJobData?.job_openings}}</span>
                </h6>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 text-center mb-3">
                <h6 class="mb-0">Posted on :
                  <span class="fw-bold text-primary">{{singJobData?.job_updated_on | date:'mediumDate' }}</span>
                </h6>
              </div>
              <!-- <div class="col-lg-4 col-md-4 col-sm-6 col-xs-6 text-center mb-3">
                <h6 class="mb-0">Application Count:
                  <span class="text-warning">{{singJobData?.job_application_count}}</span>
                </h6>
              </div> -->
            </div>
          </div>
        </div>
        <div class="card br-12 mt-2"
          *ngIf="singJobData?.job_description !== '' || singJobData?.job_note !== '' || singJobData?.job_skill_requirements !== ''">
          <div class="card-body">
            <div *ngIf="singJobData?.job_description !== ''" class="mb-5 mt-3">
              <h5>Job Description</h5>
              <p class="text-black" [innerHTML]="singJobData?.job_description"></p>
            </div>
            <div *ngIf="singJobData?.job_skill_requirements !== ''" class="mb-5">
              <h5>Job Skill Requirements</h5>
              <p class="text-black" [innerHTML]="singJobData?.job_skill_requirements"></p>
            </div>
            <div *ngIf="singJobData?.job_note !== ''" class="mb-3">
              <h5>Additional Information</h5>
              <p class="text-black" [innerHTML]="singJobData?.job_note"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>