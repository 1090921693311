import { Component } from '@angular/core';

import { ApiService } from '../shared/Services/api.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
})
export class AboutUsComponent {
  constructor(private apiService: ApiService) {}

  ngOnInit(): void {
    this.getAboutus();
    this.getContacts();
  }

  // slideConfig = {
  //   dots: true,
  //   arrows: false,
  //   centerPadding: '0px',
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   autoplay: true,
  //   centerMode: true,
  //   autoplaySpeed: 3000,
  //   responsive: [
  //     {
  //       breakpoint: 1200,
  //       settings: {
  //         slidesToShow: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 576,
  //       settings: {
  //         slidesToShow: 1,
  //       },
  //     },
  //   ],
  // };

  aboutusData: any;
  aboutusSpecificData: any;
  getAboutus() {
    this.apiService.getAboutus().subscribe({
      next: (val: any) => {
        this.aboutusData = val;
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => {
        if (this.aboutusData.status === 1) {
          this.aboutusSpecificData = this.aboutusData.aboutus[0];
          // console.log(this.aboutusSpecificData);
        }
      },
    });
  }

  contactsData: any;
  contactsSpecificData: any;
  getContacts() {
    this.apiService.getContactsData().subscribe({
      next: (val: any) => {
        this.contactsData = val;
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => {
        if (this.contactsData.status === 1) {
          this.contactsSpecificData = this.contactsData.contacts[0];
        }
      },
    });
  }
}
