<!-- Start Breadcrumb 
    ============================================= -->
<div class="breadcrumb-area gradient-bg text-light text-center">
  <!-- Fixed BG -->
  <div class="fixed-bg" style="background-image: url(assets/img/shape/1.png)"></div>
  <!-- Fixed BG -->
  <div class="container">
    <div class="row">
      <div class="col-lg-8 offset-lg-2">
        <h1>Get in touch</h1>
        <ul class="breadcrumb">
          <li>
            <a routerLink="/"><i class="fas fa-home"></i> Home</a>
          </li>
          <li class="active">Contact</li>
        </ul>
      </div>
    </div>
  </div>
</div>
<!-- End Breadcrumb -->

<!-- Start Contact Area
    ============================================= -->
<div class="contact-area default-padding bg-gray">
  <div class="container">
    <div class="contact-items">
      <div class="row">
        <div class="col-lg-4 left-item">
          <div class="info-items bg-dark">
            <!-- Single Item -->
            <div class="item">
              <div class="icon">
                <i class="fas fa-map-marked-alt"></i>
              </div>
              <div class="info">
                <h5>Location</h5>
                <p>{{ contactsSpecificData?.contact_address }}</p>
              </div>
            </div>
            <!-- End Single Item -->
            <!-- Single Item -->
            <div class="item">
              <div class="icon">
                <i class="fas fa-phone"></i>
              </div>
              <div class="info">
                <h5>Make a Call</h5>
                <p>
                  <a href="tel:{{ contactsSpecificData?.contact_phone }}" class="call">
                    {{ contactsSpecificData?.contact_phone }}
                  </a>
                </p>
              </div>
            </div>
            <!-- End Single Item -->
            <!-- Single Item -->
            <div class="item">
              <div class="icon">
                <i class="fas fa-envelope-open"></i>
              </div>
              <div class="info">
                <h5>Send a Mail</h5>
                <p>
                  <a href="mailto:{{ contactsSpecificData?.contact_email }}" class="webaddress">
                    {{ contactsSpecificData?.contact_email }}
                  </a>
                </p>
              </div>
            </div>
            <!-- End Single Item -->
          </div>
        </div>
        <div class="col-lg-8 right-item">
          <h2>We’d love to hear from you anytime</h2>
          <form [formGroup]="contactusForm" class="contact-form">
            <div class="row">
              <div class="col-lg-12">
                <div class="form-group">
                  <input class="form-control" id="name" name="name" placeholder="Name" type="text"
                    formControlName="enquiry_name" />
                  <div *ngIf="valid['enquiry_name'].touched && valid['enquiry_name'].invalid" class="show-error">
                    <div *ngIf="valid['enquiry_name'].errors?.['required']">
                      Name is required
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <div class="form-group">
                  <input class="form-control" id="email" name="email" placeholder="Email*" type="email"
                    required="required" formControlName="enquiry_email" />
                  <div class="show-error" *ngIf="valid['enquiry_email'].touched && valid['enquiry_email'].invalid">
                    <div class="text-danger"
                      *ngIf="valid['enquiry_email'].errors?.['required'] && valid['enquiry_email'].dirty">
                      Please enter email address
                    </div>
                    <div class="text-danger"
                      *ngIf="valid['enquiry_email'].errors?.['email'] && valid['enquiry_email'].dirty">
                      Please enter a valid email address
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group">
                  <input class="form-control" id="phone" name="phone" placeholder="Subject" type="text"
                    required="required" formControlName="enquiry_subject" />
                  <span class="alert-error"></span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="form-group comments">
                  <textarea class="form-control" id="comments" name="comments" placeholder="Tell Us About Project *"
                    required="required" formControlName="enquiry_message"></textarea>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <button class="btn circle btn-md btn-gradient wow fadeInUp" data-wow-duration="1.8s" type="submit"
                  name="submit" id="submit" [disabled]="contactusForm.invalid" (click)="addContactForm()">
                  Send Message <i class="fa fa-paper-plane"></i>
                </button>
              </div>
            </div>
            <!-- Alert Message -->
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Contact -->