import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SolutionsService {
  backendApiURL: any;
  constructor(private Http: HttpClient) {
    this.backendApiURL = environment.backendURL;
  }
  getAllServices() {
    return this.Http.get(this.backendApiURL + 'service').pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }
  getServiceById(service_id: any) {
    return this.Http.get(this.backendApiURL + 'service/' + service_id).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  getTemplateByID(template_id: any) {
    return this.Http.get(this.backendApiURL + 'template/' + template_id).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  getSectionByid(section_id: any) {
    return this.Http.get(this.backendApiURL + 'section/' + section_id).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  getStripByid(strip_id: any) {
    return this.Http.get(this.backendApiURL + 'strip/' + strip_id).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }
}
