import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { SolutionsService } from '../shared/Services/solutions.service';

@Component({
  selector: 'app-service-detail',
  templateUrl: './service-detail.component.html',
})
export class ServiceDetailComponent {
  id: any;
  private parametersObservable: any;
  constructor(
    private activatedRoute: ActivatedRoute,
    private solutionService: SolutionsService,
    private route: Router
  ) {}
  ngOnInit(): void {
    this.parametersObservable = this.activatedRoute.params.subscribe(
      (params) => {
        this.id = this.activatedRoute.snapshot.params['id'];
        this.getServiceById(this.id);
        this.getAllServices(this.id);
      }
    );
  }

  serviceApiData: any;
  serviceData: any;
  service_template: any;
  service_loader: boolean = false;
  // sectionData: any;
  sectionsSrotedData: any;
  getServiceById(service_id: any) {
    this.solutionService.getServiceById(service_id).subscribe({
      next: (val: any) => {
        // console.log(val);
        this.serviceApiData = val;
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => {
        if (this.serviceApiData.status === 1) {
          this.serviceData = this.serviceApiData.service;
          this.service_loader = true;

          this.sectionsSrotedData =
            this.serviceData?.service_sections_data.sort(
              (a: any, b: any) => a.section_order - b.section_order
            );
          // console.log(this.serviceData);
          // this.getTemplateById(this.service_template);
        }
      },
    });
  }

  servicesApiData: any;
  serviceAllData: any;
  relatedServices: any = [];
  getAllServices(id: any) {
    this.solutionService.getAllServices().subscribe({
      next: (val: any) => {
        // console.log(val);
        this.servicesApiData = val;
      },
      error: (err: any) => {},
      complete: () => {
        if (this.servicesApiData.status === 1) {
          this.serviceAllData = this.servicesApiData.services.sort(
            (a: any, b: any) => a.service_order - b.service_order
          );
          this.relatedServices = this.serviceAllData.filter(
            (item: any) => item.service_id !== id
          );

          // console.log(this.relatedServices);
        }
      },
    });
  }

  ngOnDestroy() {
    if (this.parametersObservable != null) {
      this.parametersObservable.unsubscribe();
    }
  }
  navigatetoDetail(service_id: any) {
    this.route.navigate(['services', service_id]);
  }
}
